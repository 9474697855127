<template>
	<Dialog :dialog="serverDialog" :dialog-width="dialog_width" @close="$emit('close', true)">
		<template v-slot:title> {{ serverId ? "Update " : "Add " }}Server </template>
		<template v-slot:body>
			<v-layout class="px-4">
				<v-row>
					<v-col class="blue lighten-5 pb-2 mb-2" cols="12" lg="12" md="12">
						<!-- <label for="provider-type">Provider Type</label> -->
						<v-radio-group
							id="provider-type"
							v-model="server.provider_type"
							row
							class="mt-0"
							hide-details
							:loading="pageLoading"
						>
							<v-radio label="Company" value="company"></v-radio>
							<v-radio label="Client" value="client"></v-radio>
						</v-radio-group>
					</v-col>

					<template v-if="server.provider_type == 'company'">
						<v-col cols="12" lg="6" md="6">
							<label for="service-provider" :class="{ required: server.provider_type == 'company' }"
								>Service Provider</label
							>
							<v-autocomplete
								id="service-provider"
								:items="service_providers"
								:loading="pageLoading"
								item-text="text"
								item-value="value"
								hide-details
								outlined
								placeholder="Service Provider"
								v-model="server.service_provider"
								:rules="[vrules.required(server.service_provider, 'Service Provider')]"
								:class="{
									required: !server.service_provider,
								}"
							>
							</v-autocomplete>
						</v-col>
					</template>

					<v-col cols="12" lg="6" md="6">
						<label for="server-name" class="required">Name</label>
						<v-text-field
							outlined
							:loading="pageLoading"
							id="server-name"
							v-model="server.name"
							placeholder="Server Name"
							hide-details
							:rules="[vrules.required(server.name, 'Server Name')]"
							:class="{
								required: !server.name,
							}"
						></v-text-field>
					</v-col>

					<template v-if="server.provider_type == 'company'">
						<v-col cols="12" lg="6" md="6">
							<label for="server-cost" class="required">Cost</label>
							<!-- <NumberInput
								id="server-cost"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								style="margin-top: 0px !important"
								placeholder="Cost"
								v-model="server.cost"
								prependInnerIcon="mdi-currency-usd"
								:rules="[vrules.required(server.cost, 'Cost')]"
								:class="{
									required: !server.cost,
								}"
							></NumberInput> -->

							<TextInput
								id="server-cost"
								hide-details
								hideTopMargin
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Cost"
								v-model="server.cost"
								:class="{
									required: !server.cost,
								}"
								:rules="[vrules.required(server.cost, 'Cost')]"
								@keypress="limitDecimal($event, server.cost)"
							>
							</TextInput>
							<!-- prefix="$" -->
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="activation-date" class="required">Activation Date</label>
							<!-- <v-text-field outlined id="activation-date" hide-details></v-text-field> -->
							<DatePicker
								:hideTopMargin="true"
								depressed
								clearable
								required
								:mandatory="true"
								:loading="pageLoading"
								placeholder="Activation Date"
								v-model="server.activation_date"
								@change="dateFormatting"
								@click:clear="server.activation_date = null"
								:rules="[vrules.required(server.activation_date, 'Activation Date')]"
								:class="{
									required: !server.activation_date,
								}"
							></DatePicker>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="renewal-basis" class="required">Renewal Basis</label>
							<!-- <v-text-field outlined id="renewal-basis" hide-details></v-text-field> -->
							<SelectInput
								hide-details
								:items="[
									{ title: 'Monthly', value: 'monthly' },
									{ title: 'Yearly', value: 'yearly' },
								]"
								:disabled="pageLoading"
								customClass=""
								:loading="pageLoading"
								placeholder="Renewal Basis"
								item-text="title"
								item-value="value"
								v-model="server.renewal_basis"
								@change="dateFormatting"
								:rules="[vrules.required(server.renewal_basis, 'Renewal Basis')]"
								:class="{
									required: !server.renewal_basis,
								}"
							></SelectInput>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="renewal-date" class="required">Renewal Date</label>
							<!-- <v-text-field outlined id="renewal-date" hide-details></v-text-field> -->
							<DatePicker
								:hideTopMargin="true"
								depressed
								clearable
								required
								:mandatory="true"
								placeholder="Renewal Date"
								readonly
								:loading="pageLoading"
								v-model="server.renewal_date"
								@click:clear="server.renewal_date = null"
								:rules="[vrules.required(server.renewal_date, 'Renewal Date')]"
								:class="{
									required: !server.renewal_date,
								}"
							></DatePicker>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="server-cost">RAM</label>
							<NumberInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								style="margin-top: 0px !important"
								placeholder="RAM"
								v-model="server.Ram"
								suffix="GB"
							></NumberInput>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="server-cost">No of Cores</label>
							<NumberInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								style="margin-top: 0px !important"
								placeholder="No of Cores"
								v-model="server.no_of_cores"
							></NumberInput>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="server-name">Storage</label>
							<v-text-field
								outlined
								id="server-name"
								placeholder="Storage"
								:loading="pageLoading"
								prepend-inner-icon="mdi-sd"
								v-model="server.storage"
								hide-details
							></v-text-field>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="server-name">No of Database</label>
							<v-text-field
								outlined
								id="server-name"
								placeholder="No of Database"
								hide-details
								:loading="pageLoading"
								v-model="server.no_of_database"
								prepend-inner-icon="mdi-database"
							></v-text-field>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="server-name">Email Notification Group</label>
							<v-autocomplete
								id="email-notification-group"
								:items="emailNotificationGroups"
								:loading="pageLoading"
								item-text="group_name"
								item-value="id"
								hide-details
								outlined
								placeholder="Email notification group"
								v-model="server.email_group"
							>
							</v-autocomplete>
						</v-col>

						<v-col cols="12" lg="6" md="6">
							<label for="server-name">Remark</label>
							<TextAreaInput
								hide-details
								dense
								style="margin-top: 0px !important"
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Remark"
								:word-limit="200"
								v-model="server.remark"
							></TextAreaInput>
						</v-col>
					</template>
				</v-row>
			</v-layout>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="pageLoading"
				depressed
				color="blue darken-4"
				tile
				@click="submit"
			>
				{{ serverId ? "Update" : "Add" }}
			</v-btn>
			<v-btn depressed tile v-on:click="closeDialog">Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import { GET, POST } from "@/core/services/store/request.module";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/DatePicker";
import SelectInput from "@/view/components/SelectInput";
import NumberInput from "@/view/components/NumberInput";
import TextInput from "@/view/components/TextInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import Dialog from "@/view/components/Dialog";
import MomentJS from "moment-timezone";
import objectPath from "object-path";
import { filter, toString } from "lodash";
import MainMixin from "@/core/mixins/main.mixin.js";
export default {
	mixins: [MainMixin],
	name: "Update-SEOProject",
	title: "Update SEO Project",
	props: {
		serverDialog: {
			type: Boolean,
			default: false,
		},
		serverId: {
			type: [String, Number],
			default: null,
		},
	},
	components: {
		TextAreaInput,
		DatePicker,
		Dialog,
		SelectInput,
		NumberInput,
		TextInput,
	},
	watch: {
		serverId: {
			immediate: true,
			handler(id) {
				if (id) {
					this.getServer(id);
				}
			},
		},
	},
	data() {
		return {
			pageLoading: false,
			dialog_width: null,
			service_providers: [],
			emailNotificationGroups: [],
			server: {
				name: null,
				provider_type: "company",
				service_provider: null,
				cost: null,
				activation_date: null,
				renewal_basis: null,
				renewal_date: null,
				Ram: null,
				no_of_cores: null,
				storage: null,
				no_of_database: null,
				email_group: null,
				remark: null,
			},
		};
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		goBack() {
			this.$router.go(-1);
		},
		limitDecimal($event, value) {
			let string = toString(value);

			// console.log($event.keyCode); //keyCodes string
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (
				string != null /* (string.indexOf(".") > -1 && keyCode == 46)  ||*/ &&
				string.includes(".") &&
				string.split(".")[1]?.length > 1
			) {
				$event.preventDefault();
			}
		},
		getEmailNotificationGroups() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: "email-groups",
				})
				.then((data) => {
					if (data) {
						this.emailNotificationGroups = data;
					}
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		dateFormatting() {
			let ac_date = objectPath.get(this.server, "activation_date");
			let ren_basis = objectPath.get(this.server, "renewal_basis");
			if (ac_date && ren_basis) {
				let date = MomentJS(ac_date, "YYYY-MM-DD");
				date.add(ren_basis == "yearly" ? 12 : 1, "months");
				this.server.renewal_date = date.subtract(1, "days").format("YYYY-MM-DD");
			}
		},
		submit() {
			this.$store
				.dispatch(POST, {
					url: `server`,
					data: { ...this.server, id: this.serverId ? this.serverId : null },
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Server added successfully.",
						},
					]);
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `service-provider-dropdown`,
				})
				.then((data) => {
					this.service_providers = filter(data, (row) => row.is_hosting == 1);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getServer(id = this.serverId) {
			console.log("getServer");
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `server/${id}`,
				})
				.then((data) => {
					this.server.name = data.name || null;
					this.server.provider_type = data.provider_type || "monthly";
					this.server.service_provider = data.service_provider || null;
					this.server.cost = data.cost || null;
					this.server.activation_date = data.activation_date || null;
					this.server.renewal_basis = data.renewal_basis || null;
					this.server.renewal_date = data.renewal_date || null;
					this.server.Ram = data.Ram || null;
					this.server.no_of_cores = data.no_of_cores || null;
					this.server.storage = data.storage || null;
					this.server.no_of_database = data.no_of_database || null;
					this.server.remark = data.remark || null;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		closeDialog() {
			this.$emit("close");
		},
	},
	mounted() {
		let screenWidth = document.body.clientWidth;
		let dialog_width = (screenWidth / 100) * 70;
		this.dialog_width = dialog_width;
		this.getSetting();
		this.getEmailNotificationGroups();
	},
};
</script>
