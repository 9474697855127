<template>
	<div>
		<v-layout class="py-3 px-3">
			<v-flex class="">
				<div class="fw-600 sub-heading primary--text">Server</div>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="d-flex justify-content-end">
				<v-btn
					v-if="!isDetail"
					class="ml-2 white--text"
					dense
					tile
					@click="addServer"
					color="red darken-4"
				>
					<v-icon class="mr-1" left> mdi-plus</v-icon> Add Server
				</v-btn>
				<v-btn
					v-if="isDetail"
					class="white--text ml-2"
					dense
					depressed
					tile
					@click="editServer"
					color="blue darken-4"
				>
					<!-- <v-icon class="mr-1" left> mdi-pencil</v-icon>  -->Edit
				</v-btn>
				<v-btn
					v-if="isDetail && getValue(detail_data, 'provider_type') == 'company'"
					class="white--text ml-2"
					dense
					depressed
					tile
					@click="renewDialog = true"
					color="blue darken-4"
				>
					<!-- <v-icon class="mr-1" left> mdi-pencil</v-icon>  -->Renew
				</v-btn>
				<div v-if="!isDetail" class="d-flex justify-end mb-1">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template #activator="{ on, attrs }">
							<v-btn
								color="blue darken-4"
								class="ml-2 text-white"
								tile
								min-height="26"
								x-small
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>

						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('ssl')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols?.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('ssl')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols?.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</div>
				<v-btn depressed class="ml-2" dense tile v-on:click="goBack()">
					<v-icon class="ml-1">mdi-arrow-left</v-icon>
					Back
				</v-btn>
			</v-flex>
		</v-layout>
		<div class="px-3">
			<!-- Detail start -->
			<template v-if="isDetail">
				<ServerDetails
					:renewDialog="renewDialog"
					@close="renewDialog = false"
					:refress="refress"
					@refress="refress = false"
				/>
			</template>
			<!-- Detail End -->

			<!-- Listing stated -->
			<template v-else>
				<!-- <v-layout class="px-4 py-2"> -->
				<template v-if="pageLoading">
					<div class="mx-20 my-40 text-center">
						<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
					</div>
				</template>
				<div v-else class="v-data-table bt-table theme--light">
					<div class="v-data-table__wrapper table_height" style="height: calc(100vh - 269px)">
						<table class="w-100">
							<thead>
								<tr>
									<th v-for="(th, th_idx) in allowedSortedThead" :width="70" :key="th.key + '_' + th_idx">
										<template v-if="th.key != 'id'">
											{{ th.name }}
										</template>
									</th>
								</tr>
							</thead>
							<tbody>
								<template v-if="tbody.length">
									<tr v-for="(td, td_idx) in tbody" :key="'server_' + td_idx">
										<template v-for="(th, th_idx) in allowedSortedThead">
											<td :key="'server_data_$_' + th.key + '_' + th_idx" @click="routeToDetail(td, th)">
												<template v-if="th.key == 'action'">
													<div class="d-flex justify-space-around align-center">
														<v-tooltip top>
															<template #activator="{ on, attrs }">
																<v-icon
																	@click="doAction('update', td)"
																	size="20"
																	v-on="on"
																	v-bind="attrs"
																	class="cursor-pointer"
																	color="blue"
																	>mdi-pencil
																</v-icon>
															</template>
															<span>Edit Server</span>
														</v-tooltip>
													</div>
												</template>

												<template v-if="th.key == 'name'">
													{{ td.name }}
												</template>

												<template v-if="th.key == 'provider_type'">
													<v-chip v-if="getValue(td, 'provider_type')" label outlined color="red darken-4">{{
														getValue(td, "provider_type")
													}}</v-chip>
													<em v-else class="text-muted">no provider type</em>
												</template>

												<template v-if="th.key == 'service_provider'">
													<v-chip label v-if="td.service_provider" outlined color="green darken-4">{{
														td.service_provider
													}}</v-chip>
													<em v-else class="text-muted">no service provider</em>
												</template>

												<template v-if="th.key == 'cost'">
													<v-chip v-if="td.cost" label outlined color="orange darken-4">{{
														formatMoney(td.cost)
													}}</v-chip>
													<em v-else class="text-muted">no cost</em>
												</template>

												<template v-if="th.key == 'specifications'">
													<div class="">
														<div class="d-flex justify-space-between">
															<label style="width: 80px; font-weight: 600" class="mb-1">RAM : </label>
															<div v-if="td.Ram">
																<v-chip class="white--text" label color="teal lighten-1" x-small>
																	<v-icon small left>mdi-cpu-64-bit</v-icon>
																	{{ td.Ram }}&nbsp;GB
																</v-chip>
															</div>
															<div v-else>
																<em class="text-muted">no RAM</em>
															</div>
														</div>
														<div class="d-flex justify-space-between">
															<label style="width: 80px; font-weight: 600" class="">Cores : </label>
															<v-chip
																v-if="td.no_of_cores"
																class="white--text mb-1 mr-1"
																label
																x-small
																color="purple"
															>
																{{ td.no_of_cores }}
															</v-chip>
															<em v-else class="text-muted">no cores</em>
														</div>
														<div class="d-flex justify-space-between mr-1">
															<label style="width: 80px; font-weight: 600" class="mb-1">Database : </label>
															<div class="d-flex align-center">
																<v-chip
																	color="red darken-4"
																	class="white--text"
																	x-small
																	label
																	v-if="td.no_of_database"
																>
																	<v-icon small left>mdi-database</v-icon>
																	{{ td.no_of_database }}
																</v-chip>
																<em class="text-muted" v-else>no Databases</em>
															</div>
														</div>
														<div class="d-flex justify-space-between mr-1">
															<label style="width: 80px; font-weight: 600" class="mb-1">Storage : </label>
															<div class="d-flex align-center">
																<v-chip color="yellow darken-4" class="white--text" x-small label v-if="td.storage">
																	<v-icon small left>mdi-sd</v-icon>
																	{{ td.storage }}
																</v-chip>
																<em class="text-muted" v-else>no storage</em>
															</div>
														</div>
													</div>
												</template>

												<template v-if="th.key == 'date'">
													<div class="">
														<div class="d-flex justify-space-between">
															<label style="width: 80px; font-weight: 600" class="mb-1">Activation : </label>
															<div v-if="td.activation_date">
																<v-chip class="" outlined label color="teal lighten-1" x-small>
																	<v-icon small left>mdi-calendar</v-icon>
																	{{ formatDate(td.activation_date) }}
																</v-chip>
															</div>
															<div v-else>
																<em class="text-muted">no activation date</em>
															</div>
														</div>
														<div class="d-flex justify-space-between">
															<label style="width: 80px; font-weight: 600" class="">Renewal : </label>
															<div v-if="td.renewal_date">
																<v-chip class="mb-1" outlined label x-small color="purple">
																	<v-icon small left>mdi-calendar</v-icon>
																	{{ formatDate(td.renewal_date) }}
																</v-chip>
															</div>
															<div v-else>
																<em class="text-muted">no renewal date</em>
															</div>
														</div>

														<div class="d-flex justify-space-between">
															<label style="width: 80px; font-weight: 600" class="">Renewal Basis : </label>
															<div v-if="td.renewal_basis">
																<v-chip class="mb-1" outlined label x-small color="red darken-4">
																	<v-icon small left>mdi-update</v-icon>
																	{{ td.renewal_basis[0].toUpperCase() + td.renewal_basis.slice(1) }}
																</v-chip>
															</div>
															<div v-else>
																<em class="text-muted">no RAM</em>
															</div>
														</div>
													</div>
												</template>

												<template v-if="th.key == 'added_at'">
													<div class="">
														<div class="d-flex justify-space-between">
															<label style="width: 80px; font-weight: 600" class="mb-1">Added by : </label>
															<div v-if="td.added_by">
																<v-chip class="" outlined label color="teal lighten-1" x-small>
																	<v-icon small left>mdi-account</v-icon>
																	{{ td.added_by }}
																</v-chip>
															</div>
															<div v-else>
																<em class="text-muted">no added by</em>
															</div>
														</div>
														<div class="d-flex justify-space-between">
															<label style="width: 80px; font-weight: 600" class="">Added at : </label>
															<div v-if="td.added_at">
																<v-chip class="mb-1" outlined label x-small color="purple">
																	<v-icon small left>mdi-calendar</v-icon>
																	{{ formatDate(td.added_at) }}
																</v-chip>
															</div>
															<div v-else>
																<em class="text-muted">no added at</em>
															</div>
														</div>
													</div>
												</template>
											</td>
										</template>
									</tr>
								</template>
								<template v-else>
									<tr>
										<td :colspan="8">
											<p class="m-0 row-not-found text-center py-3">
												<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
												Uhh... There <span class="text-lowercase">are no server at the moment.</span>
											</p>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<v-row class="my-2">
					<v-col md="6" class="">
						<p class="m-0">{{ showingString }}</p>
					</v-col>
					<v-col md="6" class="text-right">
						<v-pagination
							color="blue darken-4"
							v-model="page"
							:length="totalPage"
							total-visible="9"
							class="listing-pagination"
							@input="updatePagination"
						></v-pagination>
					</v-col>
				</v-row>
			</template>
			<!-- </v-layout> -->
		</div>

		<AddServer
			v-if="addServerDialog"
			:server-dialog="addServerDialog"
			@close="addServerDialog = false"
			@success="getListing(), successAddUpdate()"
			:server-id="serverId"
		/>
	</div>
</template>
<script>
import { GET } from "@/core/services/store/request.module";
import objectPath from "object-path";
import AddServer from "@/view/components/AddServer.vue";

import ListingMixin from "@/core/mixins/listing.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
import { mapGetters } from "vuex";
import { SET_CURRENT_PAGE } from "@/core/services/store/listing.module";
import { SET_SEARCH_BAR } from "@/core/services/store/listing.module";
import ServerDetails from "@/view/module/setting/ServerDetails";

export default {
	name: "ServerSettings",
	mixins: [ListingMixin, HeightMixin],
	components: {
		AddServer,
		ServerDetails,
	},
	data() {
		return {
			skipBottomHeight: 57,
			page: 1,
			status: null,
			addServerDialog: false,
			renewDialog: false,
			endpoint: "server-detail",
			serverId: null,
			isDetail: false,
			detail_data: null,
			refress: false,
		};
	},
	watch: {
		"$route.query": {
			deep: true,
			handler(route) {
				if (objectPath.get(route, "tab") == "server") {
					if (!objectPath.has(route, "detailId")) {
						this.isDetail = false;
						this.getListing();
					} else {
						let id = objectPath.get(route, "detailId");
						id && this.getServerById(id);
					}
				}
			},
		},
	},
	methods: {
		hasKey(object, key) {
			return objectPath.has(object, key);
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		goBack() {
			this.$router.go(-1);
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.filterRows();
			});
		},
		successAddUpdate() {
			this.refress = true;
		},
		routeToDetail(td, th) {
			if (th.checkbox || th.key == "action") {
				return false;
			}
			this.$router.push({
				path: "setting",
				query: { tab: "server", detailId: td.id },
				// params: { id: td.id },
			});
			this.isDetail = true;
		},
		doAction(type, td) {
			switch (type) {
				case "update":
					this.serverId = td.id;
					this.addServerDialog = true;
					break;
			}
		},
		getServerById(id) {
			this.serverId = id;
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `server/${id}`,
				})
				.then((data) => {
					this.detail_data = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		addServer() {
			this.serverId = null;
			this.addServerDialog = true;
		},
		editServer() {
			this.addServerDialog = true;
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
		]),
		serverTab: {
			set(val) {
				let query = { ...this.$route.query };
				query.subTab = val;
				if (val != this.serverTab) {
					this.$router.replace({ query });
				}
			},
			get() {
				return this.$route.query.subTab || "renew";
			},
		},
	},
	mounted() {
		this.$store.dispatch(SET_SEARCH_BAR, true);
		let query = this.$route.query;
		if (objectPath.has(query, "detailId") && objectPath.get(query, "tab") == "server") {
			let detail_id = objectPath.get(query, "detailId");
			this.isDetail = true;
			this.getServerById(detail_id);
		}
	},
};
</script>
